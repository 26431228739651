/**
 * @generated SignedSource<<33c3147398f9a0373fb83d3f331d71a5>>
 * @relayHash 2120a0d53cab933f81baffd1239461c6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2120a0d53cab933f81baffd1239461c6

import type { ConcreteRequest } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ReplaceAssigneesForAssignableInput = {
  assignableId: string;
  assigneeIds: ReadonlyArray<string>;
  clientMutationId?: string | null | undefined;
};
export type updateIssueAssigneesMutation$variables = {
  input: ReplaceAssigneesForAssignableInput;
};
export type updateIssueAssigneesMutation$data = {
  readonly replaceAssigneesForAssignable: {
    readonly assignable: {
      readonly assignees?: {
        readonly nodes: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"AssigneePickerAssignee">;
        } | null | undefined> | null | undefined;
      };
      readonly id?: string;
      readonly participants?: {
        readonly nodes: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"AssigneePickerAssignee">;
        } | null | undefined> | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type updateIssueAssigneesMutation$rawResponse = {
  readonly replaceAssigneesForAssignable: {
    readonly assignable: {
      readonly __typename: "Issue";
      readonly __isNode: "Issue";
      readonly assignees: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "Bot";
          readonly __isActor: "Bot";
          readonly avatarUrl: string;
          readonly id: string;
          readonly isCopilot: boolean;
          readonly login: string;
          readonly name: string | null | undefined;
          readonly profileResourcePath: string | null | undefined;
        } | {
          readonly __typename: "User";
          readonly __isActor: "User";
          readonly avatarUrl: string;
          readonly id: string;
          readonly login: string;
          readonly name: string | null | undefined;
          readonly profileResourcePath: string | null | undefined;
        } | null | undefined> | null | undefined;
      };
      readonly id: string;
      readonly participants: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "Bot";
          readonly __isActor: "Bot";
          readonly avatarUrl: string;
          readonly id: string;
          readonly isCopilot: boolean;
          readonly login: string;
          readonly name: string | null | undefined;
          readonly profileResourcePath: string | null | undefined;
        } | {
          readonly __typename: "User";
          readonly __isActor: "User";
          readonly avatarUrl: string;
          readonly id: string;
          readonly login: string;
          readonly name: string | null | undefined;
          readonly profileResourcePath: string | null | undefined;
        } | null | undefined> | null | undefined;
      };
    } | {
      readonly __typename: string;
      readonly __isNode: string;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type updateIssueAssigneesMutation = {
  rawResponse: updateIssueAssigneesMutation$rawResponse;
  response: updateIssueAssigneesMutation$data;
  variables: updateIssueAssigneesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "login",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileResourcePath",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": 64
    }
  ],
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": "avatarUrl(size:64)"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCopilot",
      "storageKey": null
    }
  ],
  "type": "Bot",
  "abstractKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "AssigneePickerAssignee",
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Actor",
            "abstractKey": "__isActor"
          }
        ],
        "args": null,
        "argumentDefinitions": ([]/*: any*/)
      }
    ],
    "storageKey": null
  }
],
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "type": "Actor",
        "abstractKey": "__isActor"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateIssueAssigneesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReplaceAssigneesForAssignablePayload",
        "kind": "LinkedField",
        "name": "replaceAssigneesForAssignable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "assignable",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "assignees",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": "assignees(first:20)"
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "participants",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": "participants(first:10)"
                  }
                ],
                "type": "Issue",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateIssueAssigneesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReplaceAssigneesForAssignablePayload",
        "kind": "LinkedField",
        "name": "replaceAssigneesForAssignable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "assignable",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "assignees",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": "assignees(first:20)"
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "participants",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": "participants(first:10)"
                  }
                ],
                "type": "Issue",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2120a0d53cab933f81baffd1239461c6",
    "metadata": {},
    "name": "updateIssueAssigneesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "2418f6524b09a21ac5913628f1be0f9a";

export default node;

import {copilotHovercardPath, userHovercardPath} from '@github-ui/paths'

export type Actor = {
  login: string
}

export function hovercardAttributes(login: string, isCopilot = false) {
  if (isCopilot) {
    return {
      ['data-hovercard-url']: copilotHovercardPath({bot_slug: login}),
      ['data-hovercard-type']: 'copilot',
    }
  } else {
    return {
      ['data-hovercard-url']: userHovercardPath({owner: login}),
      ['data-hovercard-type']: 'user',
    }
  }
}
